import { useState, useEffect } from 'react'
import { detectCrawler, getHeaders } from "../common_js/common";
import request from '../common_js/request';
import config from "../config/config_api.json"

import axios from "axios";
import dynamic from 'next/dynamic';
import userSession from '../common_js/usersession';
const HomepageDesktop = dynamic(() => import('../pageBreakup/Homepage/HomepageDesktop'))
const HomepageMobile = dynamic(() => import('../pageBreakup/Homepage/HomepageMobile'))
import SeoHead from '@/components/Seo/SeoHead';

export default function Home(props) {
  const { isMobile: initialIsMobile, userData, login, homePageData, seoData, iscrawler, responseTime, roleName } = props;
  const [isMobile, setIsMobile] = useState(initialIsMobile);
  useEffect(() => {
    const handleResize = () => {
      if (initialIsMobile) {
        console.log("resize called");
        setIsMobile(window.innerWidth < 1024);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    console.log("responseTime", responseTime);
    //setisLoading(false)
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {console.log("Rendered")}
      <SeoHead seoData={seoData} pathname="/" isMobile={isMobile} />
      {isMobile ?
        <HomepageMobile userData={userData} login={login} homePageData={homePageData} seoData={seoData} iscrawler={iscrawler} roleName={roleName} />
        :
        <HomepageDesktop userData={userData} login={login} homePageData={homePageData} seoData={seoData} iscrawler={iscrawler} roleName={roleName} />
      }
    </>
  );
}

export async function getServerSideProps({ req, res, query }) {
  let requestTime = new Date().getTime();
  const iscrawler = detectCrawler(req.headers['user-agent']);
  const home_req = iscrawler
    ? { "section_names": ["whyChooseUs", "newBanner", "popularDestination", "PopularAmongsTravellers", "popularLocations", "exploreWithIg", "promotionalSection", "fromOurBlogs", "poweredText", "disclaimerText", "homePageBanner", "homeDesktopBanner",] }
    : { "section_names": ["whyChooseUs", "newBanner", "popularDestination", "PopularAmongsTravellers", "homePageBanner", "homeDesktopBanner", "homeMobileBanner", "AgentHomepageBanner", "AgentHomeMobileBanner"] };

  const [session, homePageData, seoData] = await Promise.all([
    userSession.setSession(req, res, query),
    getCmsContentData(req, home_req),
    iscrawler ? getCmsContentData(req, { "page_name": "SEO" }) : Promise.resolve({}),
  ]);

  if (iscrawler) session.isMobile = false;

  let responseTime = new Date().getTime() - requestTime;

  return {
    props: {
      ...session,
      homePageData,
      seoData: Object.keys(seoData?.data || {}).map(key => seoData.data[key]),
      iscrawler,
      responseTime,
      hideFooter: false,
    },
  };
}

async function getCmsContentData(req, body) {
  try {
    let url = `${config.simplica.baseurl}v1/getcmsdata`
    req.moduleName = 'CMS DATA';
    let recommendationdata = await request.post(req, url, body);
    if (recommendationdata.status) {
      return recommendationdata.data
    }
    return {
      status: false, statuscode: 301,
      message: "something wrong! Please try again",
      data: {}
    }
  } catch (err) {
    console.log(err);
    return {
      status: false,
      statuscode: 301,
      message: "something wrong! Please try again",
      data: {}
    }

  }
}
