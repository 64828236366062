import React, { Fragment } from 'react'
import Head from 'next/head';
import { useRouter } from 'next/router';
import config from "../../config/config.json";


function SeoHead(props) {
    const { seoData, pathname, isMobile } = props;
    const metaData = seoData?.seoMetaTagsGeneral || {};
    const metaProperty = seoData?.seoMetaTagsOg || {};
    const metaTwitter = seoData?.seoMetaTagsTwitter || {};
    const seoBreadCrumbData = seoData?.breadcrumbData?.breadcrumbJsonLd || false;
    const seoFaqData = seoData?.faqDetails?.faqJsonLd || false;

    const headersData = config.url.headers;
    const device = isMobile ? "mobile" : "desktop";
    const router = useRouter();
    const { query } = router;
    const region = query.region || '';

    const seoCities = props.seoCities?.map((item) => item?.label) || [];
    const extractedRegion = region.split(",")[0]?.trim().toLowerCase();

    let matchedPoweredLabel = seoCities && seoCities.find((label) =>
        extractedRegion.includes(label.toLowerCase())
    ) || 'null';
    matchedPoweredLabel = matchedPoweredLabel?.replace(/ /g, '-').toLowerCase();

    const headerScripts = headersData.find(element => element.routes.includes(pathname) && element.displayIn.includes(device))?.path;
    // Helper function to conditionally render meta tags
    const renderMetaTag = (name, content, defaultValue) => {
        return content ? <meta key={name} name={name} content={content} /> : <meta name={name} content={defaultValue} />;
    };

    const renderMetaProperty = (property, content, defaultValue) => {
        return content ? <meta key={property} property={property} content={content} /> : <meta property={property} content={defaultValue} />;
    };
    const renderLinkProperty = (property, content, defaultValue) => {
        return content ? <link key={property} rel={property} href={content} /> : <link rel={property} href={defaultValue} />
    }

    return (
        <Fragment>
            <Head>
                <title key="title">{metaData?.pageTitleContent?.plaintext || `${props?.searchText ? props?.searchText : ""}Hotel Booking, Book Cheap, Budget and Luxury Hotels – IndiGo`}</title>
                {(pathname != null && pathname == "/searchdetail") ? (
                    <>
                    {renderMetaTag('robots', metaData?.robotsContent?.plaintext, 'noindex,nofollow')}
                </>
                ) : (
                    <>
                        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
                        {renderMetaTag('robots', metaData?.robotsContent?.plaintext, 'index,follow')}
                        {renderMetaTag('template', metaData?.templateContent?.plaintext, 'page-content')}
                        {renderMetaTag('description', metaData?.descriptionContent?.plaintext, 'Book hotels online with IndiGo & avail great discounts and offers in India and worldwide on cheap, budget and luxury hotels at great prices on best hotel booking at goIndiGo.in website and app.')}
                        {renderMetaTag('Keywords', metaData?.keywordContent?.plaintext, 'Hotel, Hotels, Hotel Booking, Hotel Booking Online, Hotels Booking Online, Hotels Online, Book Hotels, Cheap Hotels Booking, Cheap Hotel Booking, Cheap Hotels, Budget Hotels Booking, Luxury Hotels Booking, Cheap Hotel Booking Online, Budget Hotels Booking Online.')}

                        {/* Open Graph meta tags */}
                        {renderMetaProperty('og:title', metaProperty?.ogTitleContent?.plaintext, 'Hotel Booking, Book Cheap, Budget and Luxury Hotels – IndiGo')}
                        {renderMetaProperty('og:description', metaProperty?.ogDescriptionContent?.plaintext, 'Book hotels online with IndiGo & avail great discounts and offers in India and worldwide on cheap, budget and luxury hotels at great prices on best hotel booking at goIndiGo.in website and app.')}
                        {renderMetaProperty('og:url', metaProperty?.ogUrlContent?.plaintext, 'https://www.goindigo.in/hotels')}
                        {renderMetaProperty('og:type', metaProperty?.ogTypeContent?.plaintext, 'website')}
                        {metaProperty?.ogImageContent?.plaintext && <meta property="og:image" content={metaProperty?.ogImageContent?.plaintext} />}
                        {renderMetaProperty('og:locale', metaProperty?.ogLocaleContent?.plaintext, 'en')}

                        {/* Twitter meta tags */}
                        {renderMetaTag('twitter:card', metaTwitter?.twitterCardContent?.plaintext, 'summary')}
                        {renderMetaTag('twitter:site', metaTwitter?.twitterSiteContent?.plaintext, '@IndiGo6E')}
                        {renderMetaTag('twitter:title', metaTwitter?.twitterTitleContent?.plaintext, 'Hotel Booking, Book Cheap, Budget and Luxury Hotels – IndiGo')}
                        {renderMetaTag('twitter:description', metaTwitter?.twitterDescriptionContent?.plaintext, 'Book hotels online with IndiGo & avail great discounts and offers in India and worldwide on cheap, budget and luxury hotels at great prices on best hotel booking at goIndiGo.in website and app.')}
                        {metaTwitter?.twitterImageContent?.plaintext && <meta name="twitter:image" content={metaTwitter?.twitterImageContent?.plaintext} />}
                    </>
                )}
                {
                    seoBreadCrumbData && (
                        <script
                            type="application/ld+json"
                            dangerouslySetInnerHTML={{ __html: JSON.stringify(seoBreadCrumbData) }}
                        />
                    )
                }
                {
                    seoFaqData && (
                        <script
                            type="application/ld+json"
                            dangerouslySetInnerHTML={{ __html: JSON.stringify(seoFaqData) }}
                        />
                    )
                }

                {renderLinkProperty(
                    'canonical',
                    metaProperty?.ogUrlContent?.plaintext || (matchedPoweredLabel && matchedPoweredLabel !== 'null' ? `https://www.goindigo.in/hotels/hotels-in-${matchedPoweredLabel}` : 'https://www.goindigo.in/hotels'),
                    'https://www.goindigo.in/hotels'
                )}
                <script src={config.url.loginUrl}></script>
                <script src={headerScripts}></script>
            </Head>
        </Fragment>
    );
}

export default SeoHead